import React from 'react';
import { render, screen, cleanup } from '@testing-library/react';
import '@testing-library/jest-dom/extend-expect';
import SplitsTable from '../SplitsTable';

const initialProps = {
  splitsData: {
    splits: [],
    currentSplits: [],
    pageLimit: 1
  },
  setSplitState: jest.fn(),
  totalAmount: ''
};

const propsWithData = {
  ...initialProps,
  splitsData: {
    splits:[{id: 1}],
    currentSplits: [{id: 1}],
    pageLimit: 1
  },
};

afterEach(cleanup);

describe('SplitsTable', () => {
  it('should render null with initial props', () => {
    const { queryByTestId } = render(<SplitsTable {...initialProps}/>);
    expect(queryByTestId('table')).not.toBeInTheDocument();
  });

  it('should render with data', () => {
    const { asFragment } = render(<SplitsTable {...propsWithData}/>);
    expect(asFragment()).toMatchSnapshot();
  });
});
import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    const ctrl = this;

    $(document).on('opened', '#dialog-zoom-search', function() {
      ctrl.zoomSearchOpened();
    });

    $(document).on('click', '#close-zoom-search', function() {
      ctrl.zoomSearchClosed();
    });

    $(document).on('click', '#search-tab', function() {
      ctrl.selectTab($(this));
      ctrl.searchTabSelected();
    });

    $(document).on('click', '#balance-tab', function() {
      ctrl.selectTab($(this));
      ctrl.balanceTabSelected();
    });

    $(document).on('click', '#search-balance-tab', function() {
      ctrl.selectTab($(this));
      ctrl.searchAndBalanceTabSelected();
    });

    $(document).on('click', '#comments-tab', function() {
      ctrl.selectTab($(this));
      ctrl.commentsTabSelected();
    });

    $(document).on('click', '#toggle-zoom-item', function() {
      ctrl.toggleFrontRear();
    });

    $(document).on('rendered', '#coupon_pane', function() {
      ctrl.handleItemChange('coupon');
    });

    $(document).on('rendered', '#check_pane', function() {
      ctrl.handleItemChange('check');
    });

    $(document).on('click', '#dialog-zoom-search .next-item', function() {
      const item_name = ctrl.zoomSearch.data('item_name');
      const next_button_selector = '#next_' + item_name;

      $(next_button_selector).trigger('click');
    });

    $('.go-to-page-zoom-input').keypress(function(event) {
      if (event.keyCode == 13) {
        $('#go-to-page-zoom-submit').click();
      }
    });

    $(document).on('click', '#go-to-page-zoom-submit', function() {
      showSpinner();
      const item_name = ctrl.zoomSearch.data('item_name');
      const desired_page = $('.go-to-page-zoom-control input').val();
      $('.go-to-page-zoom-control input').val('');
      go_to_page(item_name, parseInt(desired_page));
    });

    $(document).on('click', '#dialog-zoom-search .previous-item', function() {
      const item_name = ctrl.zoomSearch.data('item_name');
      const prev_button_selector = '#previous_' + item_name;

      $(prev_button_selector).trigger('click');
    });
  }

  get screen() {
    return $(this.element);
  }

  get content() {
    return this.screen.closest('#content');
  }

  get zoomSearch() {
    return this.screen.find('#dialog-zoom-search');
  }

  get itemWrapper() {
    return this.zoomSearch.find('.item_wrapper');
  }

  get itemsColumn() {
    return this.screen.find('.items-column');
  }

  get detailsColumn() {
    return this.screen.find('.details-column');
  }

  get tabsColumn() {
    return this.screen.find('.zoom-search-tabs-column');
  }

  get commentsColumn() {
    return this.screen.find('.comments-column');
  }

  get validationColumn() {
    return this.screen.find('.validation-column');
  }

  get searchTab() {
    return this.screen.find('#search-tab');
  }

  get searchAndBalanceTab() {
    return this.screen.find('#search-balance-tab');
  }

  get balanceTab() {
    return this.screen.find('#balance-tab');
  }

  get commentsTab() {
    return this.screen.find('#comments-tab');
  }

  get itemFront() {
    return this.zoomSearch.find('.displayed_item .front');
  }

  get itemRear() {
    return this.zoomSearch.find('.displayed_item .rear');
  }

  get itemOriginal() {
    return this.zoomSearch.find('.displayed_item .original');
  }

  get frontRearToggle() {
    return this.zoomSearch.find('#toggle-zoom-item');
  }

  handleItemChange(item) {
    if (this.zoomSearch.is(':hidden')) return;

    $(`.displayed_${item} .${item}_link.${item}_front`).trigger('click');
  }

  toggleFrontRear() {
    this.zoomSearch.find('.displayed_item .hidden').not('.original')
      .removeClass('hidden')
      .siblings()
      .addClass('hidden');

    this.updateFrontRearToggleText();
  }

  updateFrontRearToggleText() {
    const text = this.itemFront.hasClass('hidden') ? 'Front' : 'Rear';
    this.frontRearToggle.text(text);
  }

  zoomSearchOpened() {
    $('#dialog-zoom-search').css('height', '400px');
    $('.ui-resizable-handle').css('top', '375px');
    this.extendContent();
    this.itemsColumn.hide();
    this.tabsColumn.show();
    this.updateFrontRearToggleText();
    this.updatePaginationInfo();
    let mode = sessionStorage.getItem('zoom-search-mode');
    switch (mode) {
    case 'combined':
      this.searchAndBalanceTab.trigger('click');
      break;

    case 'balance':
      this.balanceTab.trigger('click');
      break;

    case 'comments':
      this.commentsTab.trigger('click');
      break;

    case 'search':
      this.searchTab.trigger('click');
      break;
    }

    this.itemWrapper
      .css('max-height', '100%')
      .removeClass('height-sm height-lg');

    this.detailsColumn
      .removeClass('col-xl-7 col-md-8')
      .addClass('col-lg-8 offset-lg-2');

    this.commentsColumn
      .removeClass(this.commentsColumn.data('default-classes'))
      .addClass(this.commentsColumn.data('zoomed-classes'))
      .hide();

    this.validationColumn
      .addClass('zoom-search-validation')
      .hide();

    if (mode == 'combined' || mode == 'balance') {
      this.validationColumn.show();
    } else if (mode == 'comments') {
      this.commentsColumn.show();
    } else {
      $('#balance_wrapper').hide();
      this.validationColumn.hide();
    }

    this.zoomSearch.slideDown('fast');
  }

  zoomSearchClosed() {
    $('#ok-loupe').remove();
    const item_id = this.zoomSearch.data('item_id');
    if ( ! $('.magnify-lock-notice').hasClass('hidden') ) {
      magnifyDocument();
      $('.magnify-lock-notice').addClass('hidden');
    }
    if ( $('#magnify_document').hasClass('active') ) {
      $('#magnify_document').click();
    }

    this.resetContent();
    this.itemsColumn.show();
    this.tabsColumn.hide();

    this.detailsColumn
      .removeClass('col-lg-8 offset-lg-2')
      .addClass('col-xl-7 col-md-8');

    this.commentsColumn
      .removeClass(this.commentsColumn.data('zoomed-classes'))
      .addClass(this.commentsColumn.data('default-classes'))
      .show();

    this.validationColumn
      .removeClass('zoom-search-validation')
      .show();

    if (!new_account_mode) {
      $('#accounts_wrapper').show();
    }
    $('#balance_wrapper').show();
    $('#flags_and_validation_pane .validation-column').show();

    this.zoomSearch.slideUp('fast');
    this.resetZoomSearch();
    saveMarker(item_id);
    enableInvoiceSearchContextMenu();
    if ( typeof(resetDocumentHOCR) === 'function' ) { resetDocumentHOCR(); }
  }

  resetZoomSearch() {
    this.itemFront.html('');
    this.itemRear.html('');
    this.itemOriginal.html('');

    this.itemWrapper.each(function() {
      if ($(this).find('.lb-wrap').length) {
        $(this).removeAttr('style');
        $(this).removeAttr('vratio');
        $(this).removeAttr('hratio');
        $(this).find('.lb-v-scrollbar').remove();
        $(this).find('.lb-h-scrollbar').remove();
        $(this).find('.lb-content').unwrap();
        $(this).find('.displayed_coupon').unwrap();
        $(this).find('.displayed_check').unwrap();
        $(this).find('.displayed_item').unwrap();
      }
    });
  }

  extendContent() {
    this.content.css('top', '-40px');
  }

  resetContent() {
    this.content.css('top', '');
  }

  searchTabSelected() {
    $('#accounts_wrapper').show();
    $('#balance_wrapper').hide();
    this.commentsColumn.hide();
    this.validationColumn.hide();
    sessionStorage.setItem('zoom-search-mode', 'search');
  }

  searchAndBalanceTabSelected() {
    $('#accounts_wrapper').show();
    $('#balance_wrapper').show();
    this.commentsColumn.hide();
    this.validationColumn.show();
    sessionStorage.setItem('zoom-search-mode', 'combined');
  }

  balanceTabSelected() {
    $('#accounts_wrapper').hide();
    $('#balance_wrapper').show();
    this.commentsColumn.hide();
    this.validationColumn.show();
    sessionStorage.setItem('zoom-search-mode', 'balance');
  }

  commentsTabSelected() {
    $('#accounts_wrapper').hide();
    $('#balance_wrapper').hide();
    this.commentsColumn.show();
    this.validationColumn.hide();
    sessionStorage.setItem('zoom-search-mode', 'comments');
  }

  selectTab(tab) {
    tab
      .addClass('active')
      .attr('aria-expanded', true)
      .siblings()
      .removeClass('active')
      .attr('aria-expanded', false);
  }

  updatePaginationInfo() {
    const item_name = this.zoomSearch.data('item_name');
    const item_pane = $(`#${item_name}_pane`);
    const item_total = parseInt(item_pane.data('count'));
    const active_item_selector = '.sequences .active a.' + item_name + '_sequence';
    const active_item = item_pane.find(active_item_selector);
    const item_number = parseInt(active_item.text());
    const info = this.zoomSearch.find('.item-count');
    const prev_button = this.zoomSearch.find('.previous-item');
    const next_button = this.zoomSearch.find('.next-item');

    if (item_total === 1) {
      prev_button.prop('disabled', true);
      next_button.prop('disabled', true);
    }
    else {
      prev_button.prop('disabled', false);
      next_button.prop('disabled', false);
      if (item_number === 1) prev_button.prop('disabled', true);
      if (item_number === item_total) next_button.prop('disabled', true);
    }

    info.html(`${item_number} of ${item_total}`);
  }
}

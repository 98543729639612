import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['editButton', 'cancelButton', 'submitButton', 'currencyCode', 'exchangeRate', 'bankFees', 'form']

  currencyCode;
  exchangeRate;
  bankFees;

  enableEditing() {
    this.currencyCode = this.currencyCodeTarget.value;
    this.exchangeRate = this.exchangeRateTarget.value;
    this.bankFees = this.bankFeesTarget.value;
    this.editButtonTarget.classList.add('hidden');
    this.submitButtonTarget.classList.remove('hidden');
    this.cancelButtonTarget.classList.remove('hidden');
    this.currencyCodeTarget.disabled = false;
    this.exchangeRateTarget.disabled = false;
    this.bankFeesTarget.disabled = false;
  }

  cancelEdit() {
    this.currencyCodeTarget.value = this.currencyCode;
    this.exchangeRateTarget.value = this.exchangeRate;
    this.bankFeesTarget.value = this.bankFees;
    this.editButtonTarget.classList.remove('hidden');
    this.submitButtonTarget.classList.add('hidden');
    this.cancelButtonTarget.classList.add('hidden');
    this.currencyCodeTarget.disabled = true;
    this.exchangeRateTarget.disabled = true;
    this.bankFeesTarget.disabled = true;
  }
}

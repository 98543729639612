import React from 'react';
import { PropTypes } from 'prop-types';

class WarningMessage extends React.Component {

  messageWellClass(showWarning) {
    const fieldClass = showWarning ? 'slidedown' : 'slideup';
    return `${fieldClass}`;
  }

  render() {
    return (
      <div id="slider" className={this.messageWellClass(this.props.showWarning)}>
        <div  className={`alert alert-${this.props.warningType} slider-content ${this.props.alignment}`}>
          {this.props.message}
        </div>
      </div>
    );
  }
}

WarningMessage.propTypes = {
  alignment: PropTypes.string.isRequired,
  showWarning:  PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.number
  ]),
  message: PropTypes.string,
  warningType: PropTypes.string.isRequired
};

export default WarningMessage;

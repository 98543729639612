import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    $(document).on('click', 'a', function(event) {
      $(this).blur();
      event.preventDefault;
      return false;
    });

    this.printReady();
  }

  printReady() {
    const ctrl = this;
    const total_images = $('img').length;
    let images_loaded = 0;

    if (total_images == 0) {
      ctrl.triggerPrint();
    }
    else {
      $('img').bind('load', function() {
        images_loaded += 1;
        if (total_images == images_loaded) ctrl.triggerPrint();
      })
      .each(function() {
        if (this.complete) $(this).trigger('load');
      });
    }
  }

  triggerPrint() {
    window.print();
  }
}

import React from 'react';
import { PropTypes } from 'prop-types';

class Icon extends React.Component {

  STYLES = {
    solid: "fas",
    regular: "far"
  }

  SIZES = {
    large: "fa-lg"
  }

  TYPES = {
    bank: 'university'
  }

  render() {
    const type = this.TYPES[this.props.type] || this.props.type;
    const style = this.STYLES[this.props.style];
    const size = this.SIZES[this.props.size];
    const classes = [style];

    if (this.props.spin) classes.push('fa-spin');
    if (size) classes.push(size);
    if (this.props.fixedWidth) classes.push('fa-fw');

    return (
      <i
        aria-hidden
        className={`${classes.join(' ')} fa-${type}`}
      />
    );
  }
}

Icon.propTypes = {
  type: PropTypes.string.isRequired,
  style: PropTypes.string,
  spin: PropTypes.bool,
  size: PropTypes.string,
  fixedWidth: PropTypes.bool
};

Icon.defaultProps = {
  style: "solid",
  fixedWidth: true
};

export default Icon;

import { http } from 'shared/api';

export default class MobilePayment {
  static update(mobilePayment, params = {}) {
    return http.put(`mobile_payments/${mobilePayment.id}`, {
      mobile_payment: params
    });
  }

  static uploadImage(mobilePayment, imageType, imageData) {
    return http.post(`mobile_payments/${mobilePayment.id}/mobile_checks`, {
      image_type: imageType,
      image_string: imageData
    });
  }

  static confirm(mobilePayment, params = {}) {
    return http.post(`mobile_payments/${mobilePayment.id}/mobile_confirmation`, params);
  }
}

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Screen from './screen';
import * as actionCreators from './actions';

function mapStateToProps(state) {
  return state;
}

function mapDispachToProps(dispatch) {
  return bindActionCreators(actionCreators, dispatch);
}

export default connect(mapStateToProps, mapDispachToProps)(Screen);

import "@babel/polyfill";
import "@eastdesire/jscolor";
import "jquery";
import "jquery-ui";
import "bootstrap"; // must be loaded before jQuery UI
import "jquery-ujs";
import "controllers";
import "expose-loader?exposes=AutoNumeric!autonumeric";
import "expose-loader?exposes=QrScanner!qr-scanner";

import 'styles/application';

$.fn.bsButton = $.fn.button.noConflict(); // reverts $.fn.button to jQuery UI
$.fn.bsTooltip = $.fn.tooltip.noConflict(); // reverts $.fn.tooltip to jQuery UI

const componentRequireContext = require.context("components", true);
const ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);

import { objectToArray } from "./reducer";

const inputWithHeaders = {
  'Lockbox': { imported: 'Imported', mobile: 'Mobile', requested: 'PayNow', lockbox: 'Scanned'},
  'Other': { eca: 'ECA', portal: 'Portal', external: 'External' }
};

const inputWithoutHeaders = {
  'checks_only': 'Checks Only',
  'one_to_one': 'One to One'
};

describe('ObjectToArray', () => {
  it('should create an array of objects with header and items for the header', () => {
    const output = objectToArray(inputWithHeaders);

    expect(output.length).toEqual(7);
    expect(output[0].header).toEqual('Lockbox');
    expect(output[0].id).toEqual('imported');
    expect(output[0].value).toEqual('imported');
    expect(output[0].label).toEqual('Imported');
  });

  it('returns a normal array of options', () => {
    const output = objectToArray(inputWithoutHeaders);

    expect(output.length).toEqual(2);
    expect(output[0].label).toEqual('Checks Only');
    expect(output[0].id).toEqual('checks_only');
    expect(output[0].value).toEqual('checks_only');
    expect(output[0].header).toEqual(undefined);
  });
});

import * as types from './types';

export const initialState = {
  finished: false,
  splitsData: {
    splits: [],
    currentSplits: [],
    currentPage: null,
    totalPages: null,
    pageLimit: 5
  },
  amountLocked: true
};

export default function(state = initialState, { type, payload, meta }) {
  switch (type) {
    case types.SET_INITIAL_DATA: {
      return {
        ...state,
        ...payload,
        splitsData: {
          ...initialState.splitsData,
          splits: payload.payment_request.payment_request_splits
        },
        amountLocked: payload.payment_request.payment_amount_locked,
        expired: (payload.payment_request.state == 'expired'),
        finished: (payload.payment_request.state == 'completed'),
        confirmSubmitEnabled: false
      };
    }
    case types.SET_SPLIT_STATE: {
      return {
        ...state,
        splitsData: {
          ...state.splitsData,
          ...payload
        },
      };
    }
    case types.SET_AMOUNT: {
      return {
        ...state,
        amount: payload
      };
    }
    case types.SET_NOTES: {
      return {
        ...state,
        notes: payload
      };
    }
    case types.UPLOAD_IMAGE_PENDING: {
      return {
        ...state,
        [`${meta.imageType}Loading`]: true
      };
    }
    case types.UPLOAD_IMAGE_FULFILLED: {
      return {
        ...state,
        [`${meta.imageType}Loading`]: false,
        [meta.imageType]: true
      };
    }
    case types.UPLOAD_IMAGE_REJECTED: {
      return {
        ...state,
        [`${meta.imageType}Loading`]: false
      };
    }
    case types.SET_CHECK_IMAGE: {
      return {
        ...state,
        [payload.imageType]: payload.imageData
      };
    }
    case types.CONFIRM_PAYMENT_REJECTED: {
      const errors = payload.response.data.errors.map((error) => {
        return error.error_message;
      });

      const CHECK_AMOUNT_MISMATCH = 2006;

      let confirmSubmitEnabled = errors.length === 1 &&
        payload.response.data.errors[0].error_code === CHECK_AMOUNT_MISMATCH;

      return {
        ...state,
        errors,
        confirmSubmitEnabled
      };
    }
    case types.CONFIRM_PAYMENT_FULFILLED: {
      return {
        ...state,
        finished: true
      };
    }
    case types.CLEAR_ERRORS: {
      return {
        ...state,
        errors: null
      };
    }
    default:
      return state;
  }
}

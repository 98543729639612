import { http } from 'shared/api';

export default class Deduction {
  static updateStep(deduction, stepId) {
    return http.put(`deductions/${deduction.id}/deduction_working_step`, {
      step_id: stepId
    });
  }

  static updateType(deduction, typeId) {
    return http.put(`deductions/${deduction.id}/deduction_working_type`, {
      type_id: typeId
    });
  }

  static updateDeduction(deduction, params) {
    return http.put(`deductions/${deduction.id}/deduction_field`, {
      deduction: {
        ...params
      }
    });
  }

  static updateAssignee(deduction, assigneeId) {
    return http.put(`deductions/${deduction.id}/deduction_assignment`, {
      deduction: {
        assignee_id: assigneeId
      }
    });
  }

  static fetchNextDeduction(deduction) {
    return http.get(`deductions/next`, {
      params: {
        deduction_id: deduction.id
      }
    });
  }

  static fetchPrevDeduction(deduction) {
    return http.get(`deductions/previous`, {
      params: {
        deduction_id: deduction.id
      }
    });
  }
}

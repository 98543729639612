import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CurrencyInput from 'shared/components/CurrencyInput';

export default function DeductionForm(props) {
  const [form, setState] = useState({
    amount: props.deduction.amount
  });

  const handleAmountChange = (amount) => {
    setState({
      ...form,
      amount
    });
  };

  const handleFieldChange = (event) => {
    const { name, value } = event.target;
    setState({
      ...form,
      [name]: value
    });
  };

  const handleSubmit = () => {
    props.onSubmit(form);
  };

  const amountField = () => {
    return (
      <div className="form-group form-group-sm">
        <label className="control-label" htmlFor="amount">
          Amount
        </label>
        <CurrencyInput
          className="form-control amount-field"
          id="amount"
          name="amount"
          placeholder="Enter Amount"
          value={form.amount}
          onChange={handleAmountChange}
        />
      </div>
    );
  };

  const deductionTypeField = () => {
    return (
      <div className="form-group form-group-sm">
        <label className="control-label" htmlFor="deduction_type_id">
          Deduction Type
        </label>
        <select
          className="form-control"
          id="deduction_type_id"
          name="deduction_type_id"
          defaultValue={props.deduction.type.id}
          onChange={handleFieldChange}
          disabled={props.submitting}
        >
          {props.deduction_types.map((deduction_type) => {
            return (
              <option key={deduction_type.id} value={deduction_type.id}>
                {deduction_type.description}
              </option>
            );
          })}
        </select>
      </div>
    );
  };

  const referenceNumberField = () => {
    return (
      <div className="form-group form-group-sm">
        <label className="control-label" htmlFor="reference_number">
          {props.feature.deduction_reference_number_label}
        </label>
        <input
          type="textfield"
          id="reference_number"
          name="reference_number"
          className="form-control reference-number-field"
          defaultValue={props.deduction.reference_number}
          onChange={handleFieldChange}
          disabled={props.submitting}
        />
      </div>
    );
  };

  const descriptionField = () => {
    return (
      <div className="form-group form-group-sm">
        <label className="control-label" htmlFor="description">
          Description
        </label>
        <textarea
          id="description"
          name="description"
          className="form-control description-field"
          rows={3}
          defaultValue={props.deduction.description}
          onChange={handleFieldChange}
          disabled={props.submitting}
        />
      </div>
    );
  };

  const addressFields = () => {
    if (!addressRequired(form.deduction_type_id)) return;

    return (
      <fieldset className="address-form">
        <legend>Address</legend>
        {addressAttnField()}
        <div className="row">
          <div className="col-lg-6">
            {address1Field()}
          </div>
          <div className="col-lg-6">
            {address2Field()}
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4">
            {addressCityField()}
          </div>
          <div className="col-lg-4">
            {addressStateField()}
          </div>
          <div className="col-lg-4">
            {addressZipField()}
          </div>
        </div>
        {addressCountryField()}
      </fieldset>
    );
  };

  const addressRequired = (deduction_type_id) => {
    const { deduction, deduction_types } = props;

    if (deduction_type_id) {
      return deduction_types.find((type) => {
        return (type.id == deduction_type_id && type.address_required);
      });
    }
    else if (deduction.type) {
      return deduction.type.address_required;
    }
  };

  const addressAttnField = () => {
    return (
      <div className="form-group form-group-sm">
        <label className="control-label" htmlFor="address_attn">
          Name
        </label>
        <input
          onChange={handleFieldChange}
          id="address_attn"
          name="address_attn"
          className="form-control address-attn-field"
          defaultValue={props.deduction.address_attn}
          disabled={props.submitting}
        />
      </div>
    );
  };

  const address1Field = () => {
    return (
      <div className="form-group form-group-sm">
        <label className="control-label" htmlFor="address_1">
          Address 1
        </label>
        <input
          onChange={handleFieldChange}
          id="address_1"
          name="address_1"
          className="form-control address-1-field"
          defaultValue={props.deduction.address_1}
          disabled={props.submitting}
        />
      </div>
    );
  };

  const address2Field = () => {
    return (
      <div className="form-group form-group-sm">
        <label className="control-label" htmlFor="address_2">
          Address 2
        </label>
        <input
          onChange={handleFieldChange}
          id="address_2"
          name="address_2"
          className="form-control address-2-field"
          defaultValue={props.deduction.address_2}
          disabled={props.submitting}
        />
      </div>
    );
  };

  const addressCityField = () => {
    return (
      <div className="form-group form-group-sm">
        <label className="control-label" htmlFor="city">
          City
        </label>
        <input
          onChange={handleFieldChange}
          id="city"
          name="city"
          className="form-control city-field"
          defaultValue={props.deduction.city}
          disabled={props.submitting}
        />
      </div>
    );
  };

  const addressStateField = () => {
    return (
      <div className="form-group form-group-sm">
        <label className="control-label" htmlFor="address_state">
          State
        </label>
        <input
          onChange={handleFieldChange}
          id="address_state"
          name="address_state"
          className="form-control address-state-field"
          defaultValue={props.deduction.address_state}
          disabled={props.submitting}
        />
      </div>
    );
  };

  const addressZipField = () => {
    return (
      <div className="form-group form-group-sm">
        <label className="control-label" htmlFor="zip_code">
          Postal Code
        </label>
        <input
          onChange={handleFieldChange}
          id="zip_code"
          name="zip_code"
          className="form-control zip-code-field"
          defaultValue={props.deduction.zip_code}
          disabled={props.submitting}
        />
      </div>
    );
  };

  const addressCountryField = () => {
    return (
      <div className="form-group form-group-sm">
        <label className="control-label" htmlFor="country">
          Country
        </label>
        <input
          onChange={handleFieldChange}
          id="country"
          name="country"
          className="form-control country-field"
          defaultValue={props.deduction.country}
          disabled={props.submitting}
        />
      </div>
    );
  };

  const formActions = () => {
    return (
      <div className="form-actions">
        <button
          className="btn btn-primary btn-sm"
          type="button"
          onClick={handleSubmit}
          children="Save"
          disabled={props.submitting}
        />
        <button
          className="btn btn-link btn-sm"
          type="button"
          onClick={props.onCancel}
          children="Cancel"
          disabled={props.submitting}
        />
      </div>
    );
  };

  return (
    <div className="deduction-form">
      {amountField()}
      {deductionTypeField()}
      {referenceNumberField()}
      {descriptionField()}
      {addressFields()}
      {formActions()}
    </div>
  );
}

DeductionForm.propTypes = {
  deduction_types: PropTypes.array.isRequired,
  deduction: PropTypes.object.isRequired,
  feature: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
};

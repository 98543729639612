import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [ 'viewButton', 'row' ]

  get screen() {
    return this.element;
  }

  get selectedRow() {
    return this.screen.querySelector('#agenda_monitor_table .selected');
  }

  highlightRow() {
    this.rowTargets.forEach((el, i) => {
      el.classList.remove('selected');
    });

    event.target.parentElement.classList.add('selected');

    this.viewButtonTarget.classList.remove('disabled');
    this.viewButtonTarget.href = this.selectedRow.dataset['viewUrl'];
  }
}

import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [ 'editButton', 'row' ]

  get screen() {
    return this.element;
  }

  get editBtn() {
    return this.screen.querySelector('#edit-lambda_function');
  }

  get selectedRow() {
    return this.screen.querySelector('#lambda_functions_table .selected');
  }

  highlightRow() {
    this.rowTargets.forEach((el, i) => {
      el.classList.remove('selected');
    });

    event.target.parentElement.classList.add('selected');

    this.editButtonTarget.classList.remove('disabled');
  }

  edit() {
    if (this.editButtonTarget.classList.contains('disabled')) {
      return;
    }

    const url = this.selectedRow.dataset['editUrl'];
    showSpinner();
    $.get(url);
  }
}

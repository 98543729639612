import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    const ctrl = this;

    $(document).on('click', '#add-sso-authenticator', function() {
      showSpinner();
    });

    $(document).on('click', '#edit-sso-authenticator', function() {
      ctrl.editClicked($(this));
    });

    $(document).on('click', '#sso_authenticators_table .highlightable', function() {
      ctrl.rowClicked($(this));
    });

    $(document).on('click', '#sso_roles_table .highlightable', function() {
      ctrl.roleRowClicked($(this));
    });

    $(document).on('change', '#sso_authenticator_idp_public_key_enabled', function() {
      ctrl.togglePemContent();
    });

    $(document).on('change', '#sso_authenticator_encryption_enabled', function() {
      ctrl.togglePemContent();
    });

    $(document).on('change', '#sso_authenticator_idp_multi_cert_enabled', function() {
      ctrl.toggleMultiCert();
    });

    $(document).on('click', '#add-sso-role', function() {
      showSpinner();
    });

    $(document).on('change', '#sso_role_type', function() {
      ctrl.toggleRoleField(true);
    });

    window.initSsoAuthenticatorForm = () => {
      ctrl.togglePemContent();
      ctrl.toggleMultiCert();
    };

    window.initSsoRoleForm = () => {
      ctrl.toggleRoleField();
    };
  }

  get screen() {
    return $(this.element);
  }

  get editAuthenticatorBtn() {
    return this.screen.find('#edit-sso-authenticator');
  }

  get selectedAuthenticatorRow() {
    return this.screen.find('#sso_authenticators_table .selected');
  }

  rowClicked(row) {
    if (row.hasClass('selected')) return;

    row.addClass('selected').siblings().removeClass('selected');

    this.editAuthenticatorBtn.enableButton();

    const rolesUrl = row.data('sso-roles-url');

    if (rolesUrl) {
      showSpinner();
      $.get(rolesUrl);
    }
    else {
      $('#add-sso-role').disableButton();
      $('#add-sso-role').attr('href', '');
      $('#sso_roles_pane .instructions').show();
      $('#sso_roles').html('');
    }
  }

  editClicked() {
    if (this.editAuthenticatorBtn.hasClass('disabled')) return;

    const url = this.selectedAuthenticatorRow.data('edit-url');
    showSpinner();
    $.get(url);
  }

  roleRowClicked(row) {
    showSpinner();
    $.get(row.data('edit-url'));
  }

  togglePemContent() {
    const publicKeyEnabled = $('#sso_authenticator_idp_public_key_enabled').is(':checked');
    const encryptionEnabled = $('#sso_authenticator_encryption_enabled').is(':checked');
    const multiCertEnabled = $('#sso_authenticator_idp_multi_cert_enabled').is(':checked');
    const showPublicKey = ((publicKeyEnabled || encryptionEnabled) && !multiCertEnabled);
    const showPrivateKey = (encryptionEnabled || multiCertEnabled);
    $('.idp_public_key_content').toggle(showPublicKey);
    $('.dade_private_key_content').toggle(showPrivateKey);
  }

  toggleMultiCert() {
    const multiCertEnabled = $('#sso_authenticator_idp_multi_cert_enabled').is(':checked');

    if (multiCertEnabled) {
      $('.idp_signing_cert_content').show();
      $('.idp_encryption_cert_content').show();
      $('.idp_public_key_content').hide();
      $('.dade_private_key_content').show();
    }
    else {
      $('.idp_signing_cert_content').hide();
      $('.idp_encryption_cert_content').hide();
      this.togglePemContent();
    }
  }

  toggleRoleField(resetRole = false) {
    const roleType = $('#sso_role_type').val();
    const managerRoleField = $('#sso_manager_role');
    const operatorRoleField = $('#sso_operator_role');

    if (resetRole) {
      managerRoleField.find('option:selected').removeAttr('selected');
      operatorRoleField.find('option:selected').removeAttr('selected');
    }

    switch (roleType) {
      case 'SsoManagerRole':
        this.showField(managerRoleField);
        this.hideField(operatorRoleField);
        break;
      case 'SsoOperatorRole':
        this.hideField(managerRoleField);
        this.showField(operatorRoleField);
        break;
      default:
        this.hideField(managerRoleField);
        this.hideField(operatorRoleField);
    }
  }

  showField(field) {
    field.prop('disabled', false).closest('.form-group').show();
  }

  hideField(field) {
    field.prop('disabled', true).closest('.form-group').hide();
  }
}

import React from 'react';
import { default as PropTypes } from 'prop-types';
import { default as ReactDatePicker } from 'react-datepicker';

export const DatePicker = (props) => {
  const isWeekday = (date) => {
    const day = date.getDay();
    return day !== 0 && day !== 6;
  };

  const filterDate = props.excludeWeekends ? isWeekday : null;

  const convertToDate = (momentObjectOrString) => {
    if (!momentObjectOrString) {
      return;
    }
    if (momentObjectOrString instanceof Date) {
      return momentObjectOrString;
    }
    return typeof momentObjectOrString.toDate === 'function' ?
      momentObjectOrString.toDate() :
      new Date(momentObjectOrString);
  };

  return (
    <ReactDatePicker
      locale={props.locale}
      selected={convertToDate(props.selected)}
      filterDate={filterDate}
      maxDate={convertToDate(props.maxDate)}
      minDate={convertToDate(props.minDate)}
      customInput={props.customInput}
      disabled={props.disabled}
      dateFormat="MM/dd/yyyy"
      onChange={(date) => {
        const convertedDate = convertToDate(date);
        const isoString = convertedDate.toISOString();
        props.onChange(isoString);
      }}
      popperModifiers={{
        offset: {
          enabled: true,
          offset: "5px, 10px"
        },
        preventOverflow: {
          enabled: true,
          escapeWithReference: false,
          boundariesElement: "viewport"
        }
      }}
      inline={props.inline}
      fixedHeight={props.fixedHeight}
      selectsStart={props.selectsStart}
      selectsEnd={props.selectsEnd}
    />
  );
};

DatePicker.propTypes = {
  locale: PropTypes.string,
  selected: PropTypes.object,
  value: PropTypes.object,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  minDate: PropTypes.object,
  maxDate: PropTypes.object,
  maxRange: PropTypes.string,
  customInput: PropTypes.element,
  excludeDates: PropTypes.array,
  excludeWeekends: PropTypes.bool,
  inline: PropTypes.bool,
  fixedHeight: PropTypes.bool,
  selectsEnd: PropTypes.bool,
  selectsStart: PropTypes.bool
};

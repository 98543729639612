import React from 'react';
import { PropTypes } from 'prop-types';
import { Provider } from 'react-redux';
import configureStore from 'store/configureStore';
import reducer from './screen/reducer';
import { default as Screen } from './screen';
import { setInitialData } from './screen/actions';

const store = configureStore(reducer);

class App extends React.Component {
  componentWillMount() {
    store.dispatch(
      setInitialData(this.props.initial_data)
    );
  }

  render() {
    return (
      <Provider store={store}>
        <Screen />
      </Provider>
    );
  }
}

App.propTypes = {
  initial_data: PropTypes.object.isRequired
};

export default App;
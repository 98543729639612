import * as types from './types';

export function setInitialData(data) {
  return {
    type: types.SET_INITIAL_DATA,
    payload: data
  };
}

export function toggleFilterItem(filter, itemId, isChecked) {
  return {
    type: types.TOGGLE_FILTER_ITEM,
    payload: { filter, itemId, isChecked }
  };
}

export function setFilter(filter, value) {
  return {
    type: types.SET_FILTER,
    payload: { filter, value }
  };
}

export function clearFilter(filter) {
  return {
    type: types.CLEAR_FILTER,
    payload: { filter }
  };
}

import { http } from 'shared/api';

export default class Comment {
  static create(deduction, comment) {
    return http.post(`deductions/${deduction.id}/deduction_comments`, {
      comment: {
        content: comment
      }
    });
  }
}
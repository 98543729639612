export const SET_INITIAL_DATA = 'SET_INITIAL_DATA';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const SET_COMMENT = 'SET_COMMENT';
export const TOGGLE_EDITING_DEDUCTION = 'TOGGLE_EDITING_DEDUCTION';

export const FETCH_EVENTS = 'FETCH_EVENTS';
export const FETCH_EVENTS_PENDING = 'FETCH_EVENTS_PENDING';
export const FETCH_EVENTS_FULFILLED = 'FETCH_EVENTS_FULFILLED';
export const FETCH_EVENTS_REJECTED = 'FETCH_EVENTS_REJECTED';

export const FETCH_PREV_DEDUCTION = 'FETCH_PREV_DEDUCTION';
export const FETCH_PREV_DEDUCTION_PENDING = 'FETCH_PREV_DEDUCTION_PENDING';
export const FETCH_PREV_DEDUCTION_FULFILLED = 'FETCH_PREV_DEDUCTION_FULFILLED';
export const FETCH_PREV_DEDUCTION_REJECTED = 'FETCH_PREV_DEDUCTION_REJECTED';

export const FETCH_NEXT_DEDUCTION = 'FETCH_NEXT_DEDUCTION';
export const FETCH_NEXT_DEDUCTION_PENDING = 'FETCH_NEXT_DEDUCTION_PENDING';
export const FETCH_NEXT_DEDUCTION_FULFILLED = 'FETCH_NEXT_DEDUCTION_FULFILLED';
export const FETCH_NEXT_DEDUCTION_REJECTED = 'FETCH_NEXT_DEDUCTION_REJECTED';

export const CREATE_COMMENT = 'CREATE_COMMENT';
export const CREATE_COMMENT_PENDING = 'CREATE_COMMENT_PENDING';
export const CREATE_COMMENT_FULFILLED = 'CREATE_COMMENT_FULFILLED';
export const CREATE_COMMENT_REJECTED = 'CREATE_COMMENT_REJECTED';

export const CHANGE_STEP = 'CHANGE_STEP';
export const CHANGE_STEP_PENDING = 'CHANGE_STEP_PENDING';
export const CHANGE_STEP_FULFILLED = 'CHANGE_STEP_FULFILLED';
export const CHANGE_STEP_REJECTED = 'CHANGE_STEP_REJECTED';

export const CHANGE_TYPE = 'CHANGE_TYPE';
export const CHANGE_TYPE_PENDING = 'CHANGE_TYPE_PENDING';
export const CHANGE_TYPE_FULFILLED = 'CHANGE_TYPE_FULFILLED';
export const CHANGE_TYPE_REJECTED = 'CHANGE_TYPE_REJECTED';

export const UPDATE_DEDUCTION = 'UPDATE_DEDUCTION';
export const UPDATE_DEDUCTION_PENDING = 'UPDATE_DEDUCTION_PENDING';
export const UPDATE_DEDUCTION_FULFILLED = 'UPDATE_DEDUCTION_FULFILLED';
export const UPDATE_DEDUCTION_REJECTED = 'UPDATE_DEDUCTION_REJECTED';

export const UPDATE_ASSIGNEE = 'UPDATE_ASSIGNEE';
export const UPDATE_ASSIGNEE_PENDING = 'UPDATE_ASSIGNEE_PENDING';
export const UPDATE_ASSIGNEE_FULFILLED = 'UPDATE_ASSIGNEE_FULFILLED';
export const UPDATE_ASSIGNEE_REJECTED = 'UPDATE_ASSIGNEE_REJECTED';

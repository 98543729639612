import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    const ctrl = this;

    $(document).on('click', '#return_imports_index_table .highlightable', function() {
      ctrl.returnImportRowClicked($(this));
    });

    $(document).on('click', '#returnable_item_index_table .highlightable', function() {
      ctrl.returnPaymentRowClicked($(this));
    });

    $(document).on('click', '#edit_return_check', function(event) {
      ctrl.showEditReturnCheckModal($(this), event);
    });

    $(document).on('click', '#return_items_index_table .highlightable', function() {
      ctrl.returnItemRowClicked($(this));
    });

    $(document).on('click', '#link_payment_button', function(event) {
      ctrl.linkPayment($(this), event);
    });

    $(document).on('click', '#unlink_payment_button', function(event) {
      ctrl.unlinkPayment($(this), event);
    });

    $(document).on('click', '#rejection_button', function(event) {
      ctrl.handleRejection($(this), event);
    });
  }

  showEditReturnCheckModal(button, event) {
    event.preventDefault();
    if (!button.hasClass('disabled')) {
      showSpinner();
      $.getScript($('#return_item_rows .selected').data('edit-url'));
    }
  }

  linkPayment(button, event) {
    event.preventDefault();
    if (!button.hasClass('disabled')) {
      showSpinner();
      $.post({
        url: $('#returnable_item_index_table .selected').data('link-payment-url'),
        dataType: 'script',
        success: (resp) => {
          eval(resp);
          this.setReturnItemButtons($('#return_items_index_table .selected'));
        }
      });
    }
  }

  unlinkPayment(button, event) {
    event.preventDefault();
    if (!button.hasClass('disabled')) {
      showSpinner();
      $.post({
        url: $('#return_items_index_table .selected').data('unlink-payment-url'),
        dataType: 'script',
        success: (resp) => {
          eval(resp);
          this.setReturnItemButtons($('#return_items_index_table .selected'));
        }
      });
    }
  }

  handleRejection(button, event) {
    event.preventDefault();
    if (!button.hasClass('disabled')) {
      showSpinner();

      const row = $('#return_items_index_table .selected');

      if ($('#return_items_index_table .selected #status').val() == 'rejected') {
        $.get(row.data('handle-restore-url'));
      } else {
        $.get(row.data('handle-rejection-url'));
      }

      this.setReturnItemButtons(row);
    }
  }

  returnImportRowClicked(row) {
    if (row.hasClass('selected')) return;

    row.addClass('selected').siblings().removeClass('selected');
    showSpinner();
    $.get(row.data('return-items-url'));
    $("#return_items\\.status option:selected").prop("selected", false);
    $('#unlink_payment_button').addClass('disabled');
    $('#rejection_button').addClass('disabled');
  }

  returnPaymentRowClicked(row) {
    if (row.hasClass('selected')) return;

    row.addClass('selected').siblings().removeClass('selected');
    $('#link_payment_button').enableButton();
    $.get($('#returnable_item_index_table .selected').data('show_url'));
    $("#return_items\\.status option:selected").prop("selected", false);
  }

  returnItemRowClicked(row) {
    if (row.hasClass('selected')) return;

    row.addClass('selected').siblings().removeClass('selected');
    if (typeof row.data('edit-url') !== 'undefined') {
      $('#edit_return_check').enableButton();
    } else {
      $('#edit_return_check').disableButton();
    }

    this.setReturnItemButtons(row);

    showSpinner();
    $.get(row.data('url'));
  }

  setReturnItemButtons(row) {
    const status = row.find('input[type="hidden"]').last().val();
    this.setUnlinkPaymentButton(status);
    this.setRejectionButton(status);
  }

  setUnlinkPaymentButton(status) {
    $('#unlink_payment_button').addClass('disabled');

    if (status === 'matched') {
      $('#unlink_payment_button').removeClass('disabled');
    }
  }

  setRejectionButton(status) {
    if (status === 'rejected') {
      $('#rejection_button').text('Restore Return Item');
      $('#rejection_button').removeClass('disabled');
    } else if (status === 'unmatched') { {
      $('#rejection_button').text('Reject Return Item');
      $('#rejection_button').removeClass('disabled');
    }
    } else {
      $('#rejection_button').text('Reject Return Item');
      $('#rejection_button').addClass('disabled');
    }
  }
}

import * as types from './types';

export const initialState = {
  lastChangedAt: null
};

export default function(state = initialState, { type, payload }) {
  switch (type) {
    case types.SET_INITIAL_DATA: {
      return {
        ...state,
        ...payload,
        filters: parseFilters(payload)
      };
    }
    case types.TOGGLE_FILTER_ITEM: {
      return {
        ...state,
        ...toggleFilterItem(state, payload),
        lastChangedAt: new Date()
      };
    }
    case types.SET_FILTER: {
      return {
        ...state,
        ...setFilter(state, payload),
        lastChangedAt: new Date()
      };
    }
    case types.CLEAR_FILTER: {
      return {
        ...state,
        ...clearFilter(state, payload),
        lastChangedAt: new Date()
      };
    }
    default:
      return state;
  }
}

function parseFilters(payload) {
  return payload.filters.map((filter) => {
    if (filter.items == null) return filter;

    const items = Array.isArray(filter.items) ?
      filter.items : objectToArray(filter.items);


    return {
      ...filter,
      items: applyFilter(items, filter.selected)
    };
  });
}

export function objectToArray(objMap) {
  const array = [];

  Object.keys(objMap).map(key => {
    const item = objMap[key];

    if (typeof item === 'object') {
      Object.keys(item).map(subItemKey => {
        array.push({
          id: subItemKey,
          value: subItemKey,
          label: item[subItemKey],
          header: key
        });
      });
    }
    else {
      array.push({
        id: key,
        value: key,
        label: objMap[key],
        header: undefined
      });
    }
  });

  return array;
}

function applyFilter(filter, appliedFilters) {
  if (filter == null) return;

  const filterArray = [...filter];

  filterArray.forEach((item, index) => {
    item.checked = isFilterApplied(item, appliedFilters);
  });

  return filterArray;
}

function isFilterApplied(item, appliedFilters) {
  if (!Array.isArray(appliedFilters)) return false;
  return appliedFilters.find(itemId => itemId == item.id) || false;
}

function toggleFilterItem(state, payload) {
  const { filter, itemId, isChecked } = payload;
  const filters = [...state.filters];
  const filterIndex = filters.findIndex(f => f.key == filter);
  const filterObj = filters[filterIndex];
  const items = [...filterObj.items];

  items.forEach((item, index) => {
    if (item.id == itemId) {
      items[index] = {
        ...item,
        checked: isChecked
      };
    }
    else if (filterObj.type == 'radio') {
      items[index] = {
        ...item,
        checked: false
      };
    }
  });

  filters.splice(filterIndex, 1, {
    ...filterObj,
    items
  });

  return {
    filters
  };
}

function setFilter(state, payload) {
  const { filter, value } = payload;
  const filters = [...state.filters];
  const filterIndex = filters.findIndex(f => f.key == filter);
  const filterObj = filters[filterIndex];

  filters.splice(filterIndex, 1, {
    ...filterObj,
    value
  });

  return {
    filters
  };
}

function clearFilter(state, payload) {
  const { filter } = payload;
  const filters = [...state.filters];
  const filterIndex = filters.findIndex(f => f.key == filter);
  const filterObj = filters[filterIndex];
  const items = filterObj.items ? [...filterObj.items] : [];

  items.forEach((item, index) => {
    items[index] = {
      ...item,
      checked: false
    };
  });

  filters.splice(filterIndex, 1, {
    ...filterObj,
    value: "",
    items
  });

  return {
    filters
  };
}

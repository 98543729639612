import * as types from './types';
import Deduction from '../api/deduction';
import Comment from '../api/comment';
import Event from '../api/event';

export function clearErrors() {
  return {
    type: types.CLEAR_ERRORS
  };
}

export function setInitialData(data) {
  return function(dispatch) {
    dispatch({
      type: types.SET_INITIAL_DATA,
      payload: data
    });
  };
}

export function fetchEvents(deduction) {
  return function(dispatch) {
    dispatch({
      type: types.FETCH_EVENTS,
      payload: Event.fetch(deduction)
    });
  };
}

export function fetchPrevDeduction(deduction) {
  return function(dispatch) {
    dispatch({
      type: types.FETCH_PREV_DEDUCTION,
      payload: Deduction.fetchPrevDeduction(deduction)
    });
  };
}

export function fetchNextDeduction(deduction) {
  return function(dispatch) {
    dispatch({
      type: types.FETCH_NEXT_DEDUCTION,
      payload: Deduction.fetchNextDeduction(deduction)
    });
  };
}

export function setComment(value) {
  return function(dispatch) {
    dispatch({
      type: types.SET_COMMENT,
      payload: value
    });
  };
}

export function createComment(deduction, comment) {
  return function(dispatch) {
    dispatch({
      type: types.CREATE_COMMENT,
      payload: Comment.create(deduction, comment)
    });
  };
}

export function changeStep(deduction, stepId) {
  return function(dispatch) {
    return dispatch({
      type: types.CHANGE_STEP,
      payload: Deduction.updateStep(deduction, stepId)
    });
  };
}

export function changeType(deduction, typeId) {
  return function(dispatch) {
    return dispatch({
      type: types.CHANGE_TYPE,
      payload: Deduction.updateType(deduction, typeId)
    });
  };
}

export function updateDeduction(deduction, params) {
  return function(dispatch) {
    dispatch({
      type: types.UPDATE_DEDUCTION,
      payload: Deduction.updateDeduction(deduction, params)
    });
  };
}

export function updateAssignee(deduction, assigneeId) {
  return function(dispatch) {
    dispatch({
      type: types.UPDATE_ASSIGNEE,
      payload: Deduction.updateAssignee(deduction, assigneeId)
    });
  };
}

export function toggleEditingDeduction() {
  return {
    type: types.TOGGLE_EDITING_DEDUCTION
  };
}
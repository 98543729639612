export const SET_INITIAL_DATA = 'SET_INITIAL_DATA';
export const SET_SPLIT_STATE = 'SET_SPLIT_STATE';
export const SET_AMOUNT = 'SET_AMOUNT';
export const SET_CHECK_IMAGE = 'SET_CHECK_IMAGE';
export const SET_NOTES = 'SET_NOTES';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';

export const UPDATE_PAYMENT = 'UPDATE_PAYMENT';
export const UPDATE_PAYMENT_PENDING = 'UPDATE_PAYMENT_PENDING';
export const UPDATE_PAYMENT_FULFILLED = 'UPDATE_PAYMENT_FULFILLED';
export const UPDATE_PAYMENT_REJECTED = 'UPDATE_PAYMENT_REJECTED';

export const UPLOAD_IMAGE = 'UPLOAD_IMAGE';
export const UPLOAD_IMAGE_PENDING = 'UPLOAD_IMAGE_PENDING';
export const UPLOAD_IMAGE_FULFILLED = 'UPLOAD_IMAGE_FULFILLED';
export const UPLOAD_IMAGE_REJECTED = 'UPLOAD_IMAGE_REJECTED';

export const CONFIRM_PAYMENT = 'CONFIRM_PAYMENT';
export const CONFIRM_PAYMENT_PENDING = 'CONFIRM_PAYMENT_PENDING';
export const CONFIRM_PAYMENT_FULFILLED = 'CONFIRM_PAYMENT_FULFILLED';
export const CONFIRM_PAYMENT_REJECTED = 'CONFIRM_PAYMENT_REJECTED';

import * as types from './types';

export const initialState = {
  lastEventAt: null,
  comment: '',
  commentPending: false,
  stepPending: false,
  typePending: false,
  editingDeduction: false,
  deductionPending: false,
  assigneePending: false,
  prevEnabled: true,
  nextEnabled: true,
};

export default function(state = initialState, { type, payload, meta }) {
  switch (type) {
    case types.SET_INITIAL_DATA: {
      return {
        ...state,
        ...payload
      };
    }
    case types.FETCH_EVENTS_FULFILLED: {
      return {
        ...state,
        deduction: {
          ...state.deduction,
          events: payload.data
        }
      };
    }
    case types.FETCH_PREV_DEDUCTION_FULFILLED: {
      const { deduction } = payload.data;
      if (deduction) {
        return {
          ...state,
          deduction,
          nextEnabled: true
        };
      }
      else {
        return {
          ...state,
          prevEnabled: false
        };
      }
    }
    case types.FETCH_NEXT_DEDUCTION_FULFILLED: {
      const { deduction } = payload.data;
      if (deduction) {
        return {
          ...state,
          deduction,
          prevEnabled: true
        };
      }
      else {
        return {
          ...state,
          nextEnabled: false
        };
      }
    }
    case types.SET_COMMENT: {
      return {
        ...state,
        comment: payload
      };
    }
    case types.CREATE_COMMENT_PENDING: {
      return {
        ...state,
        commentPending: true
      };
    }
    case types.CREATE_COMMENT_FULFILLED: {
      return {
        ...state,
        comment: '',
        commentPending: false,
        lastEventAt: new Date()
      };
    }
    case types.CREATE_COMMENT_REJECTED: {
      return {
        ...state,
        commentPending: false
      };
    }
    case types.CHANGE_STEP_PENDING: {
      return {
        ...state,
        stepPending: true
      };
    }
    case types.CHANGE_STEP_FULFILLED: {
      return {
        ...state,
        stepPending: false,
        lastEventAt: new Date(),
        deduction: {
          ...state.deduction,
          deduction_step: payload.data.deduction_step
        }
      };
    }
    case types.CHANGE_STEP_REJECTED: {
      return {
        ...state,
        stepPending: false
      };
    }
    case types.CHANGE_TYPE_PENDING: {
      return {
        ...state,
        typePending: true
      };
    }
    case types.CHANGE_TYPE_FULFILLED: {
      return {
        ...state,
        typePending: false,
        lastEventAt: new Date(),
        deduction: {
          ...state.deduction,
          type: payload.data.type
        }
      };
    }
    case types.CHANGE_TYPE_REJECTED: {
      return {
        ...state,
        typePending: false
      };
    }
    case types.TOGGLE_EDITING_DEDUCTION: {
      return {
        ...state,
        editingDeduction: !state.editingDeduction
      };
    }
    case types.UPDATE_DEDUCTION_PENDING: {
      return {
        ...state,
        deductionPending: true
      };
    }
    case types.UPDATE_DEDUCTION_FULFILLED: {
      const deduction = payload.data;

      delete deduction.payment_items;

      return {
        ...state,
        deductionPending: false,
        editingDeduction: false,
        lastEventAt: new Date(),
        deduction: {
          ...state.deduction,
          ...deduction,
        }
      };
    }
    case types.UPDATE_ASSIGNEE_PENDING: {
      return {
        ...state,
        assigneePending: true
      };
    }
    case types.UPDATE_ASSIGNEE_FULFILLED: {
      return {
        ...state,
        assigneePending: false,
        lastEventAt: new Date(),
        deduction: {
          ...state.deduction,
          assignee_id: payload.data.assignee_id
        }
      };
    }
    case types.UPDATE_ASSIGNEE_REJECTED: {
      return {
        ...state,
        assigneePending: false
      };
    }
    default:
      return state;
  }
}

import * as types from './types';
import MobilePayment from '../api/mobile_payment';

export function clearErrors() {
  return {
    type: types.CLEAR_ERRORS
  };
}

export function setInitialData(data) {
  return function(dispatch) {
    return dispatch({
      type: types.SET_INITIAL_DATA,
      payload: data
    });
  };
}

export function setSplitState(currentSplits, currentPage, totalPages) {
  return function(dispatch) {
    return dispatch({
      type: types.SET_SPLIT_STATE,
      payload: {
        currentSplits,
        currentPage,
        totalPages
      }
    });
  };
}

export function setAmount(amount) {
  return {
    type: types.SET_AMOUNT,
    payload: amount
  };
}

export function setCheckImage(imageType, imageData) {
  return function(dispatch) {
    return dispatch({
      type: types.SET_CHECK_IMAGE,
      payload: {
        imageType,
        imageData
      }
    });
  };
}

export function setNotes(notes) {
  return {
    type: types.SET_NOTES,
    payload: notes
  };
}

export function uploadImage(mobilePayment, imageType, imageData) {
  return function(dispatch) {
    return dispatch({
      type: types.UPLOAD_IMAGE,
      payload: MobilePayment.uploadImage(mobilePayment, imageType, imageData),
      meta: {
        imageType
      }
    });
  };
}

export function updatePayment(mobilePayment, params) {
  return function(dispatch) {
    return dispatch({
      type: types.UPDATE_PAYMENT,
      payload: MobilePayment.update(mobilePayment, params)
    });
  };
}

export function confirmPayment(mobilePayment, params = {}) {
  return function(dispatch) {
    return dispatch({
      type: types.CONFIRM_PAYMENT,
      payload: MobilePayment.confirm(mobilePayment, params)
    }).catch(() => {});
  };
}

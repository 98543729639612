import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import ListFilter from 'shared/components/ListFilter';
import TextFilter from 'shared/components/TextFilter';
import DateFilter from 'shared/components/DateFilter';
import qs from 'qs';
import './styles';
import './react_datepicker';

export default class Screen extends Component {

  componentDidUpdate(prevProps) {
    this.handleFilterChanged(prevProps);
  }

  handleFilterChanged = prevProps => {
    const { search_table, lastChangedAt } = this.props;

    if (search_table && prevProps.lastChangedAt != lastChangedAt) {
      window['submit_search']('', $(search_table));
    }
  }

  applyFilters = () => {
    const params = this.filterParams();
    this.submit(params);
  }

  resetFilters = () => {
    const params = this.resetParams();
    this.submit(params);
  }

  submit = (params) => {
    const { submit_url } = this.props;
    const queryString = qs.stringify(params, { arrayFormat: 'brackets' });

    if (submit_url) {
      window.location = submit_url + '?' + queryString;
    }
  }

  filterParams = () => {
    const params = { ...this.props.params };

    this.props.filters.forEach((filter) => {
      if (filter.type == 'text' || filter.type == 'datepicker') {
        params[filter.key] = filter.value;
      }
      else {
        const values = filter.items
          .filter(item => item.checked)
          .map(item => item.id);

        params[filter.key] = values.length ? values : '';
      }
    });

    return params;
  }

  resetParams() {
    const params = { ...this.props.params };

    this.props.filters.forEach((filter) => {
      params[filter.key] = '';
    });

    return params;
  }

  actions() {
    if (!this.props.show_actions) return;

    return (
      <div className="col-md-3 ml-auto">
        <div className="actions text-right">
          {this.resetButton()}
          {' '}
          {this.applyButton()}
        </div>
      </div>
    );
  }

  resetButton() {
    return (
      <button
        type="button"
        className="btn btn-default btn-sm"
        onClick={this.resetFilters}
      >
        <i className="fas fa-fw fa-undo" /> Reset
      </button>
    );
  }

  applyButton() {
    return (
      <button
        type="button"
        className="btn btn-success btn-sm"
        onClick={this.applyFilters}
      >
        <i className="fas fa-fw fa-check" /> Apply
      </button>
    );
  }

  filters() {
    const filters = this.props.filters.filter(filter => !filter.custom);

    return filters.map((filter) => {
      switch (filter.type) {
        case 'text': {
          return (
            <div key={filter.key} className={"col-md-3"}>
              <TextFilter
                key={filter.key}
                filterKey={filter.key}
                filterLabel={filter.label}
                filterPlaceholder={filter.placeholder}
                filterValue={filter.value}
                onChange={value => this.props.setFilter(filter.key, value)}
                clearFilter={this.props.clearFilter}
              />
            </div>
          );
        }
        case 'datepicker': {
          return (
            <div key={filter.key} className={"col-md-3"}>
              <DateFilter
                key={filter.key}
                filterKey={filter.key}
                filterLabel={filter.label}
                filterPlaceholder={filter.placeholder}
                filterValue={filter.value}
                onChange={value => this.props.setFilter(filter.key, value)}
                clearFilter={this.props.clearFilter}
              />
            </div>
          );
        }
        default: {
          return filter.items.length ? (
            <div key={filter.key} className={"col-md-3"}>
              <ListFilter
                type={filter.type}
                key={filter.key}
                filterKey={filter.key}
                filterLabel={filter.label}
                filterItems={filter.items}
                visible={filter.visible}
                hideLabelOnSelect={filter.hide_label_on_select}
                toggleFilterItem={this.props.toggleFilterItem}
                clearFilter={this.props.clearFilter}
                id={`${filter.key}_select`}
              />
            </div>
          ) : null;
        }
      }
    });
  }

  render() {
    return (
      <div className={`filters-component ${this.props.css_class}`}>
        <div className="row">
          {this.filters()}
          {this.actions()}
        </div>
      </div>
    );
  }
}

Screen.defaultProps = {
  show_actions: true
};

Screen.propTypes = {
  submit_url: PropTypes.string,
  search_table: PropTypes.string,
  show_actions: PropTypes.bool,
  params: PropTypes.object,
  css_class: PropTypes.string,
  filters: PropTypes.array.isRequired,
  toggleFilterItem: PropTypes.func.isRequired,
  setFilter: PropTypes.func.isRequired,
  clearFilter: PropTypes.func.isRequired,
  lastChangedAt: PropTypes.object
};

import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    const ctrl = this;

    $(document).on('click', '#add-domain', function() {
      showSpinner();
    });

    $(document).on('click', '#domains_index_table .highlightable', function() {
      ctrl.rowClicked($(this));
    });
  }

  rowClicked(row) {
    showSpinner();
    $.get(row.data('edit-url'));
  }
}

import moment from 'moment';
import accounting from 'accounting';

const date = function(dateParam, dateFormat = "MM/DD/YYYY") {
  return dateParam ? moment(dateParam).format(dateFormat) : '';
};

const currency = function(amount) {
  return accounting.formatMoney(amount);
};

export default {
  date,
  currency
};

import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['container']
  intervalId;


  connect() {
    this.intervalId = setInterval(() => this.updateView(), 30000);
  }

  disconnect() {
    clearInterval(this.intervalId);
  }

  updateView() {
    const analyticsPath = `/manage/banks/${this.bankId}/billers/${this.billerId}/analytics.js`;

    $.ajax({
      url: analyticsPath,
      type: 'GET',
      contentType: 'application/javascript',
      success: (response) => {
        eval(response);
      }
    });
  }

  get billerId() {
    return this.containerTarget.dataset.billerId;
  }

  get bankId() {
    return this.containerTarget.dataset.bankId;
  }
}

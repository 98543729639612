import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import promise from 'redux-promise-middleware';

export default function configureStore(rootReducer) {
  return createStore(
    rootReducer,
    {},
    applyMiddleware(thunk, promise())
  );
}
